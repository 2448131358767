@font-face {
  font-family: 'Luckiest Guy';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/LuckiestGuy-Regular.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Kanit-Light.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/Kanit-LightItalic.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Kanit-Regular.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Kanit-Medium.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/Kanit-MediumItalic.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Kanit-SemiBold.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 600;
  src: url("./assets/fonts/Kanit-SemiBoldItalic.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Kanit-Bold.ttf");
}
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/Kanit-BoldItalic.ttf");
}